import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import PlusIcon from '@/components/common/icons/catalogs/PlusIcon';
import BackIcon from '@/components/common/icons/BackIcon';
import useLanguage from '@/components/common/hooks/useLanguage';

interface Props {
  goBack: () => void;
  isAdmin: boolean;
  userId: number;
}

const ItemHeader: React.FC<Props> = ({
  goBack,
  isAdmin,
  userId,
}) => {
  const [{ common }] = useLanguage();

  return (
    <Header>
      <HeaderWrap>
        <TopButton onClick={goBack}>
          <BackIcon />
        </TopButton>
        <HeaderText>{common.user_vacations}</HeaderText>
        <HeaderSmallWrapper>
          {
            !isAdmin && (
              <HeaderRectangle to={`/request-vacation/${userId}`}>
                <HeaderPlus>
                  <PlusIcon color="black" />
                </HeaderPlus>
              </HeaderRectangle>
            )
          }
        </HeaderSmallWrapper>
      </HeaderWrap>
    </Header>
  );
};

const Header = styled.header`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
  background-color: #ffffff;
  height: 72px;
  position: fixed;
  z-index: 110;
  width: 100%;
  top: 0;
  max-width: 552px;
`;

const HeaderWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
`;

const HeaderSmallWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled.div`
  width: 100%;
  padding-bottom: 2px;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #21272e;
`;

const TopButton = styled.div`
  color: black;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;
`;

const HeaderRectangle = styled(Link)`
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: solid 1px #dae1e8;
  background-color: #f0f1f2;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color:#21272e;
`;

const HeaderPlus = styled.div`
  width: 24px;
  height: 24px;
`;

export default ItemHeader;
