import React from 'react';

interface Props {
  color?: string;
}

const ExtendIcon: React.FC<Props> = ({ color = '#333' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-code"
    style={{ transform: 'rotate(90deg)' }}
  >
    <polyline points="16 18 22 12 16 6" />
    <polyline points="8 6 2 12 8 18" />
  </svg>
);

export default ExtendIcon;
