import React from 'react';

const IndicatorCancelled: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#FEAA22">
        <g>
          <g>
            <g>
              <path d="M12 1C5.917 1 1 5.917 1 12s4.917 11 11 11 11-4.917 11-11S18.083 1 12 1zm4.73 15.73c-.429.429-1.122.429-1.551 0L12 13.551 8.821 16.73c-.429.429-1.122.429-1.551 0-.429-.429-.429-1.122 0-1.551L10.449 12 7.27 8.821c-.429-.429-.429-1.122 0-1.551.429-.429 1.122-.429 1.551 0L12 10.449l3.179-3.179c.429-.429 1.122-.429 1.551 0 .429.429.429 1.122 0 1.551L13.551 12l3.179 3.179c.418.418.418 1.122 0 1.551z" transform="translate(-30 -281) translate(0 257) translate(16 10) translate(14 14)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IndicatorCancelled;
