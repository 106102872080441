import React from 'react';

const CashIcon: React.FC = () => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512 512"
    height="24"
    viewBox="0 0 512 512"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m479.481 349.408c-.621-19.813-4.854-31.205-13.726-36.94-29.318-18.954-79.161 2.634-110.003 19.295-5.895-8.029-16.02-12.407-26.64-11.003l-41.226 5.487c-7.058.941-14.38-.104-21.174-3.02-7.938-3.407-22.716-9.745-46.431-7.037-42.595 4.866-41.383 8.351-53.118 14.001-2.696 1.216-11.918 2.714-22.508 3.795-4.663-10.526-15.204-17.893-27.439-17.893h-54.725c-16.542 0-30 13.458-30 30v135.907c0 16.542 13.458 30 30 30h54.725c13.775 0 25.404-9.336 28.911-22.01l22.37-4.07c6.297-.925-1.536-.992 133.069 8.223 37.476 2.565 73.507-11.756 97.226-36.336 74.743-76.478 62.118-63.593 63.58-65.056 11.533-11.966 17.61-27.358 17.109-43.343zm-362.266 132.592h-54.724v-135.906h54.725v135.906zm323.592-110.105c-74.744 76.477-62.111 63.586-63.574 65.049-18.292 18.979-45.817 29.174-73.62 27.27l-120.937-8.273c-13.023-1.486-20.885.71-35.46 3.36v-95.387c11.62-1.141 25.661-3.175 32.938-6.68 11.146-5.364 7.194-7.085 43.531-11.235 15.804-1.807 25.067 2.169 31.198 4.8 11.763 5.048 24.542 6.84 36.962 5.188l39.95-5.317c1.449 5.008 1.798 11.794-.098 16.015-.961 2.14-2.396 3.436-4.799 4.334-16.625 6.217-16.625 6.217-87.977 16.529-8.199 1.185-13.885 8.792-12.7 16.991 1.189 8.227 8.825 13.885 16.991 12.7 73.147-10.571 74.545-10.773 94.193-18.121 14.789-5.53 24.616-18.5 25.506-37.074 27.723-16.118 68.6-32.836 84.88-25.301.541 1.722 1.457 5.727 1.705 13.606.245 7.81-2.841 15.461-8.689 21.546z" />
      <path d="m288.491 286c78.851 0 143-64.149 143-143s-64.149-143-143-143-143 64.149-143 143 64.149 143 143 143zm0-256c62.309 0 113 50.691 113 113s-50.691 113-113 113-113-50.691-113-113 50.691-113 113-113z" />
      <path d="m250.009 206.685c8.98 5.875 15.827 8.604 23.616 9.796v2.644c0 8.284 6.716 15 15 15 8.285 0 15-6.716 15-15v-4.254c17.476-5.87 28.647-21.438 31.23-36.809 3.567-21.221-7.926-40.387-28.599-47.694-10.484-3.706-22.052-8.16-28.347-13.098-.966-.757-1.681-3.219-1.102-6.288.288-1.528 1.62-6.635 6.657-8.152 12.806-3.857 18.547 3.291 23.604 5.937 7.343 3.838 16.405.999 20.243-6.343 3.745-7.163 1.132-15.966-5.816-19.954-5.142-3.492-9.935-6.703-17.868-8.932v-2.318c0-8.284-6.716-15-15-15s-15 6.716-15 15v3.29c-29.021 9.783-36.103 49.211-14.231 66.368 9.685 7.595 23.441 13.032 36.863 17.776 8.924 3.154 9.826 9.591 9.012 14.436-1.145 6.808-6.872 14.164-16.744 14.229-11.029.093-13.904-.378-22.094-5.737-6.933-4.536-16.229-2.593-20.765 4.341-4.535 6.93-2.592 16.226 4.341 20.762z" />
    </g>
  </svg>
);

export default CashIcon;
